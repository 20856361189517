.contact_Container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    background-color: #ED4C21;
    padding: 2rem;
    margin-top: 10rem;

}
.checkBox{
    display: flex;
    align-items: center;
    gap:0.5rem !important;
    justify-content:flex-end;
    font-size: 1rem;
    margin-right: 30px;
}

.row{
    align-items: center;
}


.left_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.contact_Container .left_container h1 {
    font-family: Inter;
    font-size: 45px;
    font-weight: 600;
    line-height: 63px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;

}

.contact_Container .left_container p {
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin-bottom: 20px;
}

.contact_Container .left_container span {
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
}

.blackLine {
    height: 3px;
    width: 10px;
    background-color: #122333;
    width: 34.63px;
    margin: 20px 0px;

}


.input {
    width: 246.42px;
    height: 54.06px;
    margin-top: 20px;
    padding-left: 10px;
    background-color: #F2F8FA;
    border: 1px solid #222;
}

.firstRowInput .input {
    margin-right: 20px;
    padding-left: 10px;
    background-color: #F2F8FA;
}

.row .input {
    width: 513.41px;
    height: 54.06px;
    padding-left: 10px;
    background-color: #F2F8FA;
}

.textareaContainers {
    margin-top: 20px;
}

.textareaContainers textarea {
    width: 513.4px;
    height: 131.25px;
    padding: 10px;
    background-color: #F2F8FA;
    border: 1px solid #222;
}

@media (max-width:1400px) {
    .firstRowInput .input {
        width: 100%;
        background-color: #F2F8FA;
    }
}

@media(max-width:950px) {
    .contact_Container {
        grid-template-columns: 1fr;
    }

    .formContainer {
        padding: 1rem 2rem;
    }

    .formContainer .textareaContainers textarea {
        width: 100%;
        background-color: #F2F8FA;
    }

    .firstRowInput .row .input {
        width: 100%;
        background-color: #F2F8FA;
    }

    .row .input {
        width: 100%;
    }
}

@media(max-width:780px) {
    .contact_Container {
        padding: 1rem 2rem;
    }

    .formContainer {
        padding: 10px;
    }

}

@media(max-width:580px) {
    .contact_Container {
        padding:10px;
    }
    .left_container{
        padding: 10px;
    }
}

.sndMsg {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sndMsg button {
    background: linear-gradient(90deg, #122333 44.58%, #FFFFFF 44.59%);
    background-color: #FFFFFF !important;
    border-bottom: #FFFFFF;
}




/* light theme */

.light {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 2rem;
    margin: 4rem 0;
}

.light .left_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.light .left_container h1 {
    font-family: Inter;
    font-size: 45px;
    font-weight: 600;
    line-height: 63px;
    letter-spacing: 0em;
    text-align: left;
    color: #122333;

}

.light .left_container p {
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #122333;
    margin-bottom: 20px;
}

.light .left_container span {
    font-family: Source Sans Pro;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #122333;

}

.light button {
    background: linear-gradient(90deg, #122333 50%, #ED4C21 50%);

}

.light .input {
    width: 246.42px;
    height: 54.06px;
    margin-top: 20px;
    padding-left: 10px;
    border: none;
}


.light .formContainer .firstRowInput .input {
    background-color: #F2F8FA;

}

.light .row .input {
    width: 513.41px;
    height: 54.06px;
    padding-left: 10px;
    background-color: #F2F8FA;
}

.light .textareaContainers {
    margin-top: 20px;
}

.light .textareaContainers textarea {
    width: 513.4px;
    height: 131.25px;
    padding: 10px;
    background-color: #F2F8FA;
    border: none;
}


.orangeLine {
    height: 3px;
    width: 10px;
    background-color: #ED4C21;
    width: 34.63px;
    margin: 20px 0px;
}

@media (max-width:1400px) {
    .light .firstRowInput .input {
        width: 100%;
    }
}

@media(max-width:950px) {
    .light {
        grid-template-columns: 1fr;
    }

    .light .formContainer {
        padding: 1rem 2rem;
    }

    .light .formContainer .textareaContainers textarea {
        width: 100%;
    }

    .light .firstRowInput .row .input {
        width: 100%;
    }

    .light .row .input {
        width: 100%;
    }
}

@media(max-width:780px) {
    .light .contact_Container {
        padding: 1rem 2rem;
    }

    .light .formContainer {
        padding: 10px;
    }

}

@media(max-width:580px) {
    .light {
        padding: 0 10px;
    }
}