.tabHeader{
    display: flex;
    justify-content: space-around;
}

.container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.tabHeader h2{
    padding: 0.6rem 0;
    text-align: center;
    flex:1 1;
    border: 1px solid rgba(0, 0, 0, 0.148);
    background:  #fff;
    font-family: Inter;
    font-size: 1.7rem;
    cursor: pointer;
    border-top: 0;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.active{
    color: #ED4C21;
}

@media (max-width:740px){
    .tabHeader{
        flex-direction: column;
        gap: 1rem;
    }
    .container{
        padding: 0 0 4rem 0;
    }

}

@media (max-width:400px){
    .tabHeader h2{
        font-size: 1.5rem;
    }
}

@media (max-width:1115px){
    .container{
        padding: 0 0 0 3.5rem;
    }
}

@media (max-width:550px){
    .container{
        padding: 0 0 4rem 0;
    }
}