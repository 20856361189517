.navWrapper{
    background: #122333;
    position: fixed;
    left: 0;
    right: 0;
    top:0;
    padding: 0 8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
}
.mirroredImage {
    transform: scaleX(-1);
}
.navBarContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoWrapper{
    display: flex;
    gap:1rem;
    align-items: center;
    color: #fff;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.5625rem;
    letter-spacing: 0.0625rem;
    text-transform: capitalize;
}


.logo{
    width: 60px;
    height: 60px;
}

.logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.navOptions{
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap:2rem;
}

.navOptions ul{
    display: flex;
    list-style-type: none;
    gap: 1.9rem;
}

.navOptions ul li{
    display: flex;
    /* background-color: aqua; */
}

.navOptions ul li:hover a{
    color: #ED4C21;
}

.navOptions ul li a{
    text-decoration: none;
    /* color: #122333; */
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 95.167px;
}

.active{
    color: #ED4C21 !important;
}

.deskTop{
    display: block;
}

.mobile{
    display: none;
}

@keyframes showNav {
    0%   {transform: translateX(1000px);}
    100% {transform: translateX(0px)}
}

.hide{
    transform: translateX(1000px);

}

.show{
    transform: translateX(0);
    animation: showNav 0.3s ease-in-out;
}

@media (min-width:1400px){
    .navBarContainer{
        width: 1397px;
    }
    .navWrapper{
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

@media (max-width:1115px){
    .navWrapper{
        padding: 0 3rem;
    }
}

@media (max-width:1030px){
    .navWrapper{
        padding: 1rem 2rem;
    }
    .deskTop{
        display:none;
    }
    .mobile{
        display: block;
    }

    .mobile ul{
        flex-direction: column;
    }

    .mobile .navOptions{
        flex-direction: column;
    }

    .mobileNav{
        position: fixed;
        z-index: 99;
        width: 100%;
        bottom: 0;
        top: 0;
        right: 0;
        background: #122333bf;
        -webkit-backdrop-filter:blur(8.5px);
        backdrop-filter: blur(8.5px);
        padding: 3rem;
        display: flex;
        gap:2.5rem;
        justify-content: flex-start;
    }

    .bars{
        cursor: pointer;        
    }
    .bars svg,.cross svg{
        color: #fff;
        font-size: 2rem;
    }

    .bars svg:hover,.cross svg:hover{
        color: #ED4C21;
    }

    .cross{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
    }
    
    .mobileNav ul{
        gap: 3rem;
    }
    .mobileNav ul li{
        justify-content: center;
    }
    .mobileNav ul li a{
        line-height: 20px;
    }
}

@media (max-width:400px){
    .navWrapper{
        padding: 1rem 1rem;
    }
}