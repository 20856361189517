.tabHeader{
    display: flex;
    justify-content: space-around;
}

.container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.tabHeader h2{
    padding: 0.6rem 0;
    text-align: center;
    flex:1 1;
    border: 1px solid rgba(0, 0, 0, 0.148);
    background:  #fff;
    font-family: Inter;
    font-size: 1.7rem;
    cursor: pointer;
    border-top: 0;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.active{
    color: #ED4C21;
}

.tabContainer{
    margin-top: 4rem;
}