.layoutContainer{
    width: 100%;
    min-height: 100vh;
    padding: 0 8rem;
    background-color: #F8F8F8;
}


@media (min-width:1400px){
    .layoutContainer{
        width: 1397px;
    }
}


@media (max-width:1115px){
    .layoutContainer{
        padding: 0 3rem;
    }
}

@media (max-width:880px){
    .layoutContainer{
        padding: 0 2rem;
    }
}


@media (max-width:400px){
    .layoutContainer{
        padding: 0 1rem;
    }
}