.grey_box_panel {
    padding: 3.5rem;
    margin-top:50px ;
    padding-bottom: 0rem;
    padding-top: 0rem;
    background: #122333;
    display: flex;
    border-radius: 10px;
}


.grey_box {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 4rem;
    padding-top: 4rem;
}

.grey_box1 {
    color: #FFF;
    padding: 30px;
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3.5rem;
}

.grey_box2 {
    color: #FFF;
    font-family: Inter;
    padding: 30px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    padding-top: 40px;
}

@media(max-width:900px){
    .grey_box_panel{
    flex-direction: column;
    padding:2rem 2rem 4rem 2rem ;
    }
    .grey_box{
        padding: 0;
    }
    .grey_box1{
        padding: 0;
        line-height: 3rem;
    }
    
    .grey_box2{
        margin-top: 20px;
        padding: 0;
        
    }
}

.cycling_box {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    margin-top: -3rem;
    margin-bottom: 4rem;
}

@media screen and (max-width: 768px) {
    .cycling_box img {
        width: 400px;
    }
}
@media(max-width:568px){
    .cycling_box {
        margin-bottom: 2rem;
    }
    .cycling_box img {
        width: 300px;
    }

}
@media(max-width:500px){
    
    .cycling_box img {
        width: 300px;
    }

}

.differentiater {
    width: 100%;
}

.ourDifferentiators h2 {
    padding: 4rem;
    color: #122333;
    text-align: center;
    font-family: 'Khand', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 24.75px;
    position: relative !important;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    z-index: 1;
}

.ourDifferentiators h2::before {
    content: 'Why choose us?';
    color: #ece2e4;
    width: 100%;
    position: absolute !important;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 5.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 93.75px;
    letter-spacing: 1px;
    text-transform: uppercase;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
}

@media (max-width:980px) {
    .ourDifferentiators h2{
        line-height: 18px;
    }
    .ourDifferentiators h2::before{
    font-size: 3rem;
    text-align: center;
    line-height: 70px;
    }
    
}
@media (max-width:780px) {
    .ourDifferentiators h2{
        line-height: 18px;
    }
    .ourDifferentiators h2::before{
    font-size: 3rem;
    text-align: center;
    line-height: 60px;
    }
    
}
.parent_box {
    background-color: #F2F8FA;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;
}

.card {
    flex: 1 1 calc(33.33% - 30px);
    margin: 10px;
    padding: 30px;
    align-items: center;
   
}
@media screen and (max-width: 800px) {
    .parent_box {
        flex-direction: column;
    }
}
 @media screen and (max-width: 768px) {
    .parent_box {
        flex-direction: column;
    }
}

.cardHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #000;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.5625rem;
    letter-spacing: 0.0625rem;
    text-transform: capitalize;
}

.cardBody {
    color: #000;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0625rem;
    padding-top: 10px;
    text-align: justify;
}
