.riderContainer{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.action{
    color:white !important;
    cursor: pointer;
}

.wrapperAction{
    display: flex;
    gap: 1rem;
}

.verifyOtps{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.select select{
    width: 100%;
    border: 1px solid #D9D9D9;
    font-family: var(--instruments);
    border-radius: 10px;
    padding:0.69rem;
    font-size: 1rem;
    outline: none;
    background: #fff;
}

.badge{
    border: 1px solid #000;
    text-align: center;
    padding: 10px;
    background-color: rgb(93, 155, 31);
    color: #fff;
    border-radius: 10px;
}

.wrapperAction > span{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    border-radius: 6px;
}

.wrapperAction > span:nth-child(1){
    background-color: #00D100;
}

.wrapperAction > span:nth-child(2){
    background-color: #0000D1;
}

.wrapperAction > span:nth-child(3){
    background-color: red;
}

.wrapperAction > span svg{
    font-size: 1.1rem;
}

.pickup{
    display: grid;
    grid-template-columns: repeat(3,minmax(255px,1fr));
    grid-gap:2rem
}

.pickupContainer{
    margin-bottom: 4rem;
}

.detailsContainer{
    padding: 1rem;
    border: 1px solid black;
    border-radius: 15px;    
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.details{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.map{
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 180px;
}

.overlay{
    border-radius: 16px;
    inset: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.111) 0%, rgba(0, 0, 0, 0.093) 100%), rgba(211, 211, 211, 0.41) 50% / cover no-repeat;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 9;
}

.container{
    /* background-color:#F8F8F8 */
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about{
    flex:1 1
}

.searchSec{
    flex:1 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap:1.5rem;
}

.about h1{
    color: #000;
    font-family: Inter;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    color: #ED4C21;
    line-height: 56px; /* 56px */
}

.logout div:nth-child(1){
    cursor: pointer;
    padding:0.7rem 1rem;
    width: 250px;
    border-radius: 8px;
    border: 1px solid rgba(237, 76, 33, 0.69);
}

.logout > div:nth-child(2){
    display: none;
    width: 250px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid rgba(237, 76, 33, 0.69);
    position: absolute;
}
.logout div:nth-child(1):hover ~ .option{
    display: block !important;
    /* transform: translateY(-2px); */
}

.logout div:nth-child(2):hover{
    display: block;
}

.option .wrap{
    width: 248px !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding:0.7rem 1rem;
    background-color: #fff;
    border: none !important;
    cursor: default !important;

}

.icons {
    display: flex;
    gap:1.1rem;
}

.icons svg{
    color: #494a4bc0;
    cursor: pointer;
}

.option .wrap a{
    display: inline-block;
    text-decoration:none ;
    color:#494A4B;
    padding: 5px 0;
    font-size: 0.9rem;
    border-bottom: 1px solid #494A4B;
}

.logout div:nth-child(1) p{
    display: flex;
    justify-content:space-between;
    align-items: center;
    color: #494A4B;
    font-family: Poppins;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;    
}

.content{
    margin-top: 2rem;
    display: flex;
    align-items: flex-start;
    gap:3rem;
}

.leftSec{
    border-radius: 12px;
    background: linear-gradient(134deg, #ED4C21 0%, rgba(18, 35, 51, 0.70) 100%);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
    flex: 1;
}

.leftSec div:nth-child(1){
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap:1.5rem
}

.img{
    display: flex;
    justify-content: center;
}

.leftSec div:nth-child(1) img{
    width:100px;
    height:100px;
}

.leftSec div:nth-child(1) h1{
    color: var(--white, #FFF);
    font-family: Inter;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 33.6px */
}

.desc{
    display:flex;
    flex-direction: column;
    gap:0.6rem
}

.desc p{
    display: flex;
    align-items: center;
    gap:1rem;
    color: #fff;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 19.2px; /* 19.2px */
}

.desc p svg{
    font-size: 1rem;
}

.rightSec{
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.organization,.address{
    display: flex;
    flex-direction: column;
    gap:1rem;
    padding: 1rem;
    border-radius: 12px;
    background:  #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
}

.organization h1,.address h1{
    color: #212121;
    font-family: Inter;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
}

.organization div p{
    color: #494A4B;
    font-family: Inter;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.5px;
}

.addr{
    display: flex;
    flex-direction: column;
    gap:1.5rem;
}

.addr div{
    padding:1rem 1rem;
    flex: 1 1;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
}

.addr div h2{
    color: #212121;
    font-family: Inter;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.5px;
} 


.loginWrapper{
    position: fixed;
    backdrop-filter: blur(7px);
    inset: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editWrapper{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 70%;
    padding: 3rem 3rem;
    border-radius: 40px;
    border: 2px solid #000;
}

.headerAddr{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}

.headerAddr h1{
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1px;
}

.close{
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
}

.inputs{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.inputs input{
    width: 100%;
    flex: 1 1;
    padding: 1rem;
    border-radius: 10px;
    padding-left: 1rem;
}

.addrItem{
    display: flex;
    gap: 1rem;
    position: relative;
}

.addrItem span{
    position: absolute;
    right: 0;
    top: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0.3rem 1rem;
    color: #fff;
    background-color: #ED4C21;
}

.saveBtn{
    display: flex;
    justify-content: center;
}

.addMore{
    display: flex;
    justify-content: flex-end;
}


@media (max-width:1115px){
    .container{
        padding: 0 0 0 3.5rem;
    }
    .pickup{
        grid-template-columns: repeat(2,minmax(255px,1fr));
    }
}

@media (max-width:750px){
    .pickup{
        grid-template-columns: repeat(1,minmax(255px,1fr));
    }
 }

@media (max-width:830px){
    .header{
     flex-direction: column;
     gap: 1rem;
    }
    .about{
     align-self: flex-start;
    }
    .searchSec{
     align-self: flex-end;
    }
    .content{
     flex-direction: column;
    }
    .rightSec,.leftSec{
     width: 100%;
    }
 }

 @media (max-width:550px){
    .container{
        padding: 0 0 6rem 0;
    }

    .col{
        flex-direction: column;
        gap: 1rem;
    }

    .col > div:nth-child(1){
        align-self: flex-start;
    }

    .logout div:nth-child(1){
        width: 230px;
    }

    .option .wrap{
        width: 230px !important;
    }

    .logout > div:nth-child(2){
        width: 232px;
    }
}

.overlay svg{
    color: #fff;
    font-size: 3rem;
}

.overlay h2{
    color: #FFF;
    font-family: Khand;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.details{

}