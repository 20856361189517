.copyright,.terms_conditions{
    flex: 1 1;
}

.terms_conditions{
    display: flex;
    gap:0.3rem;
}

.phone{
    display: flex;
}

a:link {
    color:#122333;
;
  }
  
  /* visited link */
  a:visited {
    color: #122333;
;
  }
  
  /* mouse over link */
  a:hover {
    color: #122333;
;
  }
  
  /* selected link */
  a:active {
    color: #122333;
;
  }

@media screen and (min-width: 992px) {
    .footer_parent {
        display: flex;
        flex-direction: column;
        margin: 0 -8rem;
        background-color: #122333;
        color: #BAC0C3;
    }

    .footer_heading {
        display: flex;
        width: 100%;
    }

    .left_footer_heading {
        display: flex;
        width: 50%;
        background-color: #F2F8FA;
        color: #122333;
    }

    .icon-1 {
        background-color: #D4E4EA;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        width: 90px;
        color:"#000"
    }

    .icon-2 {
        background-color: #D4E4EAB2;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        width: 90px;
        color:"#000"
    }

    .icon-3 {
        background-color: #D4E4EA80;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        width: 90px;
        color:"#000"
    }

    .right_footer_heading {
        display: flex;
        width: 50%;
        background-color: #ED4C21;
        color: #FFFFFF;
        justify-content: center;
        align-items: center;
    }

    .call_us_text {
        color: #FFF;
        font-size: 1.28906rem;
        font-family: Khand;
        font-style: normal;
        font-weight: 600;
        line-height: 1.93363rem;
        letter-spacing: 0.0625rem;
        text-transform: uppercase;
    }

    .call_us_icon {
        color: #FFF;
        font-size: 1.28906rem;
        margin-left: 30px;
        margin-right: 10px;
    }

    .footer_body {
        display: flex;
        padding: 4rem 8rem;
        justify-content: space-around;
        align-items: flex-start;
        padding-bottom: 0px;
    }

    .advertisement_column {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 10px;
    }

    .logo_img {
        width: 80%;
    }

    .services_column {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 10px;
    }

    .customer_care_column {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 10px;
    }

    .col_heading {
        color: #FFF;
        font-size: 0.9375rem;
        font-family: Khand;
        font-style: normal;
        font-weight: 600;
        line-height: 1.3125rem;
        letter-spacing: 0.09375rem;
        text-transform: uppercase;
    }

    ul {
        list-style: none;
    }

    .spacing{
        padding-top: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
    }

    ul .customer_li::before,ul .services_li::before {
        content: "\2022";
        color: #ED4C21;
        font-weight: bold;
        display: inline-block;
        width: 1em;
    }

    .services_li,.customer_li {
        color: #BAC0C3;
        font-size: 0.9375rem;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        line-height: 1.78125rem;
    }

    .services_a,.customer_a {
        text-decoration: none;
    }

    .copyright {
        color: #BAC0C3;
        font-size: 0.82031rem;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        line-height: 1.46488rem;
        letter-spacing: 0.03125rem;
    }

    .footer_bottom {
        display: flex;
        justify-content: space-between;
        padding: 8rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .terms_conditions {
        color: #BAC0C3;
        text-align: center;
        font-size: 0.82031rem;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        line-height: 1.46488rem;
        letter-spacing: 0.03125rem;
    }
    .terms_conditions a{
        color: #FFF;
    }
}

@media screen and (max-width: 992px) {
    .footer_parent {
        display: flex;
        flex-direction: column;
        margin: 0 -8rem;
        background-color: #122333;
        color: #BAC0C3;
    }

    .footer_heading {
        display: flex;
        width: 100%;
    }

    .left_footer_heading {
        display: flex;
        width: 50%;
        background-color: #F2F8FA;
        color: #122333;
    }

    .icon-1 {
        background-color: #D4E4EA;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        width: 90px;
    }

    .icon-2 {
        background-color: #D4E4EAB2;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        width: 90px;
    }

    .icon-3 {
        background-color: #D4E4EA80;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        width: 90px;
    }

    .right_footer_heading {
        display: flex;
        width: 50%;
        background-color: #ED4C21;
        color: #FFFFFF;
        justify-content: center;
        align-items: center;
    }

    .call_us_text {
        color: #FFF;
        font-size: 1.28906rem;
        font-family: Khand;
        font-style: normal;
        font-weight: 600;
        line-height: 1.93363rem;
        letter-spacing: 0.0625rem;
        text-transform: uppercase;
    }

    .call_us_icon {
        color: #FFF;
        font-size: 1.28906rem;
        margin-left: 30px;
        margin-right: 10px;
    }

    .footer_body {
        display: flex;
        padding: 75px;
        justify-content: space-around;
        align-items: flex-start;
        padding-bottom: 0px;
    }

    .advertisement_column {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 10px;
    }

    .logo_img {
        width: 80%;
    }

    .services_column {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 10px;
    }

    .customer_care_column {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 10px;
    }

    .col_heading {
        color: #FFF;
        font-size: 0.9375rem;
        font-family: Khand;
        font-style: normal;
        font-weight: 600;
        line-height: 1.3125rem;
        letter-spacing: 0.09375rem;
        text-transform: uppercase;
    }

    ul {
        list-style: none;
    }

    ul .services_li::before,ul .customer_li::before {
        content: "\2022";
        color: #ED4C21;
        font-weight: bold;
        display: inline-block;
        width: 1em;
    }

    .services_li,.customer_li {
        color: #BAC0C3;
        font-size: 0.9375rem;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        line-height: 1.78125rem;
    }

    .services_a,.customer_a {
        text-decoration: none;
    }

    .copyright {
        color: #BAC0C3;
        font-size: 0.82031rem;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        line-height: 1.46488rem;
        letter-spacing: 0.03125rem;
    }

    .footer_bottom {
        display: flex;
        justify-content: space-between;
        padding: 75px;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .terms_conditions {
        color: #BAC0C3;
        text-align: center;
        font-size: 0.82031rem;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        line-height: 1.46488rem;
        letter-spacing: 0.03125rem;
    }
    .terms_conditions a{
        color: #FFF;
    }
}

@media screen and (max-width: 768px) {
    .footer_parent {
        display: flex;
        flex-direction: column;
        margin: 0 -8rem;
        background-color: #122333;
        color: #BAC0C3;
    }

    .footer_heading {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .left_footer_heading {
        display: flex;
        width: 100%;
        background-color: #F2F8FA;
        color: #122333;
    }

    .icon-1 {
        background-color: #D4E4EA;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        width: 90px;
    }

    .icon-2 {
        background-color: #D4E4EAB2;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        width: 90px;
    }

    .icon-3 {
        background-color: #D4E4EA80;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 90px;
        width: 90px;
    }

    .right_footer_heading {
        display: flex;
        width: 100%;
        padding-top:30px;
        padding-bottom:30px;
        background-color: #ED4C21;
        color: #FFFFFF;
        justify-content: center;
        align-items: center;
    }

    .call_us_text {
        color: #FFF;
        font-size: 1.28906rem;
        font-family: Khand;
        font-style: normal;
        font-weight: 600;
        line-height: 1.93363rem;
        letter-spacing: 0.0625rem;
        text-transform: uppercase;
    }

    .call_us_icon {
        color: #FFF;
        font-size: 1.28906rem;
        margin-left: 30px;
        margin-right: 10px;
    }

    .footer_body {
        display: flex;
        flex-direction: column;
        padding: 75px;
        justify-content: space-around;
        align-items: flex-start;
        padding-bottom: 0px;
        gap:2rem
    }

    .advertisement_column {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 10px;
    }

    .logo_img {
        width: 80%;
    }

    .services_column {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 10px;
    }

    .customer_care_column {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 10px;
    }

    .col_heading {
        color: #FFF;
        font-size: 0.9375rem;
        font-family: Khand;
        font-style: normal;
        font-weight: 600;
        line-height: 1.3125rem;
        letter-spacing: 0.09375rem;
        text-transform: uppercase;
    }

    ul {
        list-style: none;
    }

    ul .services_li::before,ul .customer_li::before {
        content: "\2022";
        color: #ED4C21;
        font-weight: bold;
        display: inline-block;
        width: 1em;
    }

    li {
        color: #BAC0C3;
        font-size: 0.9375rem;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        line-height: 1.78125rem;
    }

    a {
        text-decoration: none;
    }

    .copyright {
        color: #BAC0C3;
        font-size: 0.82031rem;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        line-height: 1.46488rem;
        letter-spacing: 0.03125rem;
    }

    .footer_bottom {
        display: flex;
        justify-content: space-between;
        padding: 75px;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .terms_conditions {
        color: #BAC0C3;
        text-align: center;
        font-size: 0.82031rem;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: 400;
        line-height: 1.46488rem;
        letter-spacing: 0.03125rem;
    }
    .terms_conditions a{
        color: #FFF;
    }
}


@media (max-width:1115px){
    .footer_parent{
        margin: 0 -3rem;
    }

    .footer_body{
        padding: 75px 3rem;
        padding-bottom: 0;
    }
    .footer_bottom{
        padding: 2rem 3rem;
    }
}

@media (max-width:880px){
    .footer_parent{
        margin: 0 -2rem;
    }
    .footer_body{
        padding: 75px 2rem;
        padding-bottom: 0;
    }
    .footer_bottom{
        padding: 2rem 2rem;
    }
    .right_footer_heading{
        flex: 1 1;
    }
    .left_footer_heading{
        width: auto;
    }
}

@media (max-width:500px){
    .footer_bottom{
        flex-direction: column;
        gap: 1rem;
    }
    .right_footer_heading{
        flex-direction: column;
        gap: 0.5rem;
    }

    .call_us_icon{
        margin-left: 0;
    }

}

@media (max-width:400px){
    .footer_parent{
        margin: 0 -1rem;
    }
    .footer_body{
        padding: 75px 1rem;
        padding-bottom: 0;
    }
    .footer_bottom{
        padding: 2rem 1rem;
    }

}

.footer__cont{
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #122333;
}
.footer_parent{
    width: 100%;
}

@media (min-width:1400px){
    .footer_parent{
        width: 1397px;
    }
    .footer_heading{
        position: absolute;
        left: 0;
        right: 0;
    }
}