.pnf_container {
    padding-top: 150px;
    padding-bottom: 100px;
    min-height: 60vh;
}

.pnf_inline {
    display: inline;
}

.pnf_wrapper{
    display: flex;
    gap:4rem;
    align-items: center;
    justify-content: center;
}

.pnf_oops {
    display: inline;
    color: #ED4C21;
    text-align: center;
    font-family: Audiowide;
    font-size: 5.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
}

.pnf_404 {
    display: inline;
    color: #ED4C21;
    text-align: center;
    font-family: Audiowide;
    font-size: 17.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
}

.color {
    color: #000000;
}

.pnf {
    color: #000;
    font-family: Khand;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.93363rem;
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
}

.fixWidth{
    /* background-color: #ED4C21; */
}