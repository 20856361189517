.clientContainer{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.action{
    color:white !important;
    cursor: pointer;
}

.wrapperAction{
    display: flex;
    gap: 1rem;
}

.clientTab{
    display: flex;
}

.checkWrapper{
    display: flex;
    flex-direction: column;
}

.checkBoxContainer{
    display: grid !important;
    grid-template-columns: repeat(4,1fr);
}

.checkBox{
    display: flex;
    align-items: center;
    gap:0.5rem !important
}

.checkBox label{
    width: 100%;
}

.fields{
    display: flex;
    flex-direction: column;
    gap:1.5rem;
}

.fields div {
    display: flex;
    width: 100%;
    gap: 2rem;
}

.clientTab h2{
    flex: 1 1;
    text-align: center;
    border: 1px solid black;
    border-top: 0;
    cursor: pointer;
    padding: 0.5rem 0;
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1px;
}

.tabActive{
    color: #ED4C21 !important;
}

.wrapperAction > span{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    border-radius: 6px;
}

.wrapperAction > span:nth-child(1){
    background-color: #00D100;
}

.loginWrapper{
    position: fixed;
    backdrop-filter: blur(7px);
    inset: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editWrapper{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 85%;
    padding: 1rem 3rem;
    border-radius: 20px;
    border: 2px solid #000;
    cursor: pointer;
}

.invoiceSec{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
}

.badge{
    border: 1px solid #000;
    text-align: center;
    padding: 5px 10px;
    background-color: rgba(31, 70, 155, 0.596);
    color: #fff;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
}

.saveBtn{
    display: flex;
    justify-content: center;
}

.headerAddr{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}

.headerAddr h1{
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1px;
}

.close{
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
}

.showInfo{
    width: 100%;
}

.contentInfo{
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.row{
    display: flex;
    justify-content: space-between;
    /* border: 2px solid black; */
    padding: 0.5rem;
    border-radius: 15px;
}

.row p{
    flex: 1;
    padding: 0.1rem;
}

.wrapperAction > span:nth-child(2){
    background-color: #0000D1;
}

.wrapperAction > span:nth-child(3){
    background-color: red;
}

.wrapperAction > span svg{
    font-size: 1.1rem;
}


.tabHeader{
    display: flex;
    justify-content: space-around;
}

.container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.tabHeader h2{
    padding: 0.6rem 0;
    text-align: center;
    flex:1 1;
    border: 1px solid rgba(0, 0, 0, 0.148);
    background:  #fff;
    font-family: Inter;
    font-size: 1.7rem;
    cursor: pointer;
    border-top: 0;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.active{
    color: #ED4C21;
}

.tabContainer{
    margin-top: 4rem;
}

@media (max-width:1115px){
    .container{
        padding: 0 0 0 3.5rem;
    }
}

@media (max-width:550px){
    .container{
        padding: 0 0 4rem 0;
    }
}

@media (max-width:400px){
    .tabHeader h2{
        font-size: 1.5rem;
    }
}

@media (max-width:740px){
    .tabHeader{
        flex-direction: column;
        gap: 1rem;
    }

}