.tabHeader {
  display: flex;
  justify-content: space-around;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.tabHeader h2 {
  padding: 0.6rem 0;
  text-align: center;
  flex: 1 1;
  border: 1px solid rgba(0, 0, 0, 0.148);
  background: #fff;
  font-family: Inter;
  font-size: 1.7rem;
  cursor: pointer;
  border-top: 0;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.riderContainer {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.action {
  color: white !important;
  cursor: pointer;
}

.wrapperAction {
  display: flex;
  gap: 1rem;
}

.wrapperAction > span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  border-radius: 6px;
}

.wrapperAction > span:nth-child(1) {
  background-color: #00d100;
}

.wrapperAction > span:nth-child(2) {
  background-color: #0000d1;
}

.wrapperAction > span:nth-child(3) {
  background-color: red;
}

.wrapperAction > span svg {
  font-size: 1.1rem;
}

.active {
  color: #ed4c21;
}

.tabHeader {
  display: flex;
  justify-content: space-around;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.box {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.214);
}

.box h2:nth-child(1) {
  text-align: center;
  font-family: Inter;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 62.5% */
}

.header h2 {
  font-family: Inter;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  color: #ed4c21;
  line-height: normal;
}

.box h2:nth-child(2) {
  font-family: DM Sans;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.tabHeader h2 {
  padding: 0.6rem 0;
  text-align: center;
  flex: 1 1;
  border: 1px solid rgba(0, 0, 0, 0.148);
  background: #fff;
  font-family: Inter;
  font-size: 1.7rem;
  cursor: pointer;
  border-top: 0;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.active {
  color: #ed4c21;
}

.tabContainer {
  margin-top: 4rem;
}

.tabContainer {
  margin-top: 4rem;
}

@media (max-width: 1115px) {
  .container {
    padding: 0 0 0 3.5rem;
  }
}

@media (max-width: 830px) {
  .tabHeader {
    flex-direction: column;
    gap: 1rem;
  }
}

@media (max-width: 550px) {
  .container {
    padding: 0 0 6rem 0;
  }
  .boxes {
    width: 100%;
  }
  .box{
    width: 100%;
  }
}
