.riderContainer{
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.action{
    color:white !important;
    cursor: pointer;
}

.wrapperAction{
    display: flex;
    gap: 1rem;
}

.wrapperAction > span{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    border-radius: 6px;
}

.loginWrapper{
    position: fixed;
    backdrop-filter: blur(7px);
    inset: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fields{
    display: flex;
    flex-direction: column;
    gap:1.5rem;
}

.fields div {
    display: flex;
    width: 100%;
    gap: 2rem;
}

.select select{
    width: 100%;
    border: 1px solid #D9D9D9;
    font-family: var(--instruments);
    border-radius: 10px;
    padding:0.69rem;
    font-size: 1rem;
    outline: none;
    background: #fff;
}

.editWrapper{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 85%;
    padding: 1rem 3rem;
    border-radius: 20px;
    border: 2px solid #000;
    cursor: pointer;
}

.headerAddr{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}

.headerAddr h1{
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1px;
}

.close{
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
}

.wrapperAction > span:nth-child(1){
    background-color: #00D100;
}

.wrapperAction > span:nth-child(2){
    background-color: #0000D1;
}

.wrapperAction > span:nth-child(3){
    background-color: red;
}

.wrapperAction > span svg{
    font-size: 1.1rem;
}