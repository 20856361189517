.main_Container {
    width: 100%;
    padding: 8rem 8rem;
    
}





.boxes {
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    justify-self: center;
    align-items: center;
    gap: 20px;
    margin-top: 4rem;
    padding: 0rem 4rem;
}

.box {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem 4rem;
    background-color: #F2F8FA;
    gap: 10px;
}

.box span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 800;
    line-height: 25px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #ED4C21;
    flex:1
}



iframe {
    margin: 4rem 0rem;
}

@media(max-width:950px){
    .boxes  {
        grid-template-columns: 1fr 1fr;
        place-items: center;
        padding: 1rem;
    }
}

@media(max-width:780px){
    .boxes  {
        grid-template-columns: 1fr;
        place-items: center;

    }
    .box{
        padding: 1rem;
        flex: 1;
    }
}