/* khand font */

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Khand:wght@300;400;500;600;700&display=swap');

/* Inter font */

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Khand:wght@300;400;500;600;700&display=swap');

/* Audiowide font */

@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  position: relative;
}


#root{
  display: flex;
  justify-content: center;
}


.flex{
  display: flex;
}

.flex-d{
  flex-direction: column;
}

.gap-1{
  gap: 1rem;
}