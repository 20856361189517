.orderOnline button{
    border: 1px solid #ED4C21;
    background-color: transparent;
    padding: 1rem 1.2rem;
    font-family: 'Khand', sans-serif;
    font-size: 16.875px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}

.orderOnline button:hover{
    background: #ED4C21 !important;
}

.orderOnlineDark button{
    border-width: 0px;
    background-color: transparent;
    padding: 1rem 1.2rem;
    color:#ED4C21;
    font-family: 'Khand', sans-serif;
    font-size: 16.875px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 200ms ease-in-out;
}


.orderOnlineDark button:hover{
    background: #FFFFFF !important;
}
