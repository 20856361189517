.container{
    flex: 1;
    width: 760px;
    padding: 1rem;
    border: 1px solid black;
}

.wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem 0;
}

.print{
    padding: 1rem; 
}

.header{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.desc{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.logoContainer{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.logoContainer h1{
    font-size: 1.5rem;
}

.logo{
    width: 70px;
    height: 70px;
}

.logo .Image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.invoiceInfo{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.invoiceInfo p{
    text-align: right;
}

.bottom{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.bottom p{
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.bottom p strong{
    width: 100px;
}

.printBtn{
    display: flex;
    justify-content: center;
}