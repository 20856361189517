.sidebar {
    background: #122333;
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid rgba(0, 0, 0, 0.214);
    width: 60px;
    height: 100vh;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 9999999;
}

.head {
    padding: 10px;
}

.sidebar:hover,
.sidebar:hover .logoWrapper {
    width: 250px;
    padding-left: 0.5rem;
}

.sidebar:hover .options .opt span {
    display: block;
    cursor: pointer;
}

.options .opt:hover,
.options .opt:hover>svg {
    color: #ED4C21;
}


.logoWrapper {
    margin-top: 2rem;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: flex-start;
}

.logoWrapper span {
    font-weight: 800;
    display: none;
    color: #fff;
}

.sidebar:hover .logoWrapper span {
    display: block;
}

.logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.logo img {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.options {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: flex-start;
    gap: 3rem;
}

.options .opt>svg {
    cursor: pointer;
    color: #fff;
}

.opt {
    display: flex;
    color: #fff;
    gap: 1rem;
    align-items: center;
}

.opt span {
    display: none;
}

.active {
    color: #ED4C21 !important;
}

@media (max-width:550px) {
    .sidebar {
        right: 0;
        bottom: 0;
        left: 0;
        height: 70px;
        width: 100%;
        transform: none;
        top: auto;
        z-index: 9999999;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 0;
        border-top-left-radius: 10px;
        display: flex;
        align-items: center;
        gap: 2rem;
        padding-left: 1rem;
    }

    .options {
        margin-top: 0;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
    }

    .logoWrapper {
        margin-top: 0;
    }

    .sidebar:hover,
    .sidebar:hover .logoWrapper {
        width: inherit;
    }

    .sidebar:hover .logoWrapper {
        padding-left: 1rem;
    }

    .sidebar {
        padding-left: 1rem;
    }

    .options .opt span {
        display: block;
        font-size: 0.7rem;
    }

    .logoWrapper span {
        display: none;
    }

    .sidebar:hover .logoWrapper span {
        display: none;
    }

    .logoWrapper {
        flex-direction: column;
        padding-left: 0.5rem;
    }

    .options .opt {
        flex-direction: column;
        gap: 0.5rem;
        align-items: center;
    }
}

@media (max-width:550px) {
    .sidebar {
        gap: 0.5rem;
    }
}