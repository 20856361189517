.container{
    display: flex;
    height: 88vh;
    align-items: center;
    justify-content: center;
}

.inputFields{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}



.wrapper{
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.wrapper h1{
    font-family: Inter;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    color: #ED4C21;
    line-height: normal;
}