.checkoutForm{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.cardNumber{
    border: 1px solid #D9D9D9;
    padding: 0.7rem 1rem;
    border-radius: 10px;
    /* width: 100%; */
    height: 44px;
}

.errorMessage{
    display: flex !important;
    align-items: center !important;
    gap: 0.5rem !important;
}
