.inputFieldWrapper{
    position: relative;
    width: 100%;
}


.inputFieldWrapper  input{
    width: 100%;
    outline: none;
    font-family: var(--instruments);
    font-weight: 600;
    color: #333333;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    padding: 1.1rem 1rem 0.6rem 1rem;
}

.inputFieldWrapper  label{
    position: absolute;
    color: #333333;
    font-size: 1rem;
    pointer-events: none;
    font-weight: 400;
    font-family: var(--instruments);
    letter-spacing: 1.3px;
    left: 1rem;
    top:50%;
    transition: all 300ms ease-in-out;
    transform: translateY(-50%);
}

.inputFieldWrapper  input:focus ~ label,
.inputFieldWrapper  input:not(:placeholder-shown) ~ label
{
    transform: translateY(-20px);
    font-size: 0.7rem;
}

.inputFieldWrapper  input:focus
{
    border-color: #ED4C21;
    box-shadow: 0 0 8px #f18f397e
}

.inputFieldWrapper  input[type=number]::-webkit-outer-spin-button,
.inputFieldWrapper input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.inputFieldWrapper  input[type=number] {
    -moz-appearance:textfield;
}


