.container{
    width: 100%;
    min-height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.header h2{
    font-family: Inter;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #ED4C21;
}

.badge{
    border: 1px solid #000;
    text-align: center;
    padding: 5px 10px;
    background-color: rgba(31, 70, 155, 0.596);
    color: #fff;
    border-radius: 10px;
}

.box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.214);
}

.box h2:nth-child(1){
    text-align: center;
    font-family: Inter;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 62.5% */
}

.box h2:nth-child(2){
    font-family: DM Sans;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.boxes{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.dataTable{
    margin-bottom: 3rem;
}

/* .done{
    background-color: aqua;
} */


@media (max-width:1115px){
    .container{
        padding: 0 0 0 3.5rem;
    }
}

@media (max-width:550px){
    .container{
        padding: 0 0 4rem 0;
    }
    .box{
        width: 100%;
    }

    .range{
        justify-content: center !important;
    }

}
