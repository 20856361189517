.container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.dataTable{
    /* margin-bottom: 3rem; */
}

.uploadButton{
    display: flex;
    gap: 1rem;
}

.fileMessage{
    font-weight: bold;
    color: rgb(0, 255, 98);
}

.select select{
    width: 100%;
    border: 1px solid #D9D9D9;
    font-family: var(--instruments);
    border-radius: 10px;
    padding:0.69rem;
    font-size: 1rem;
    outline: none;
    background: #fff;
}

.uploadBtn{
    display: flex;
    align-items: center;
    gap: 2rem;
}

.uploadBtn a{
    background-color: rgb(255 0 0 / 78%);
    text-decoration: none;
    color: #fff !important;
    border-radius: 10px;
    padding: 0.7rem 2rem;
    font-weight: bold;
}

@media (max-width:700px){
    .select select{
        width: 100%;
    }

    .uploadBtn{
        text-align: center;
    }
    .container{
        padding-bottom: 4rem;
    }
    .uploadButton{
        flex-direction: column;
    }
    .uploadButton div div button{
        width: 100%;
    }
    .uploadButton div button{
        width: 100%;
    }
}