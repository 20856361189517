.tabHeader{
    display: flex;
    justify-content: space-around;
}
.container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.badge{
    border: 1px solid #000;
    text-align: center;
    padding: 5px 10px;
    background-color: rgba(31, 70, 155, 0.596);
    color: #fff;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
}

.loginWrapper{
    position: fixed;
    backdrop-filter: blur(7px);
    inset: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.editWrapper{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 85%;
    padding: 3rem 3rem;
    border-radius: 40px;
    border: 2px solid #000;
    cursor: pointer;
}

.saveBtn{
    display: flex;
    justify-content: center;
}

.headerAddr{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}

.headerAddr h1{
    color: #000;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1px;
}

.showInfo{
    min-width: 500px;
    display: flex;
    justify-content: center;
}

.inputFields{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contentInfo{
    width: 100%;
    padding: 0 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.close{
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
}

.tabHeader h2{
    padding: 0.6rem 0;
    text-align: center;
    flex:1 1;
    border: 1px solid rgba(0, 0, 0, 0.148);
    background:  #fff;
    font-family: Inter;
    font-size: 1.7rem;
    cursor: pointer;
    border-top: 0;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.active{
    color: #ED4C21;
}

.tabContainer{
    margin-top: 4rem;
}

.container{
    width: 100%;
    min-height: calc(100vh - 150px);
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.header h2{
    font-family: Inter;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    color: #ED4C21;
    line-height: normal;
}

.box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1.5rem;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.214);
}

.box h2:nth-child(1){
    text-align: center;
    font-family: Inter;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 62.5% */
}

.box h2:nth-child(2){
    font-family: DM Sans;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.boxes{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.dataTable{
    margin-bottom: 3rem;
}

/* .done{
    background-color: aqua;
} */

.select{
    width: 100%;
}

.select select{
    width: 100%;
    border: 1px solid #D9D9D9;
    font-family: var(--instruments);
    border-radius: 10px;
    padding:0.69rem;
    font-size: 1rem;
    outline: none;
    background: #fff;
}

.filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:2rem
}

span.switcher {
    display: inline-block;
    position: relative;
    width:200px;
    height:50px;
    border-radius:25px;
    margin:20px 0;
}

span.switcher input {
    appearance: none;
    cursor: pointer;
    border: 1px solid #1e1e1e;
    position: relative;
    width: 200px;
    height: 50px;
    border-radius: 25px;
    background-color: #1e1e1e;
    outline: none;
    font-family: 'Oswald', sans-serif;
}

span.switcher input:before, body span.switcher input:after {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
}

span.switcher input:before {
    content: 'DELIVERY';
    left: 20px;
}

span.switcher input:after {
    content: 'PICKUP';
    right: 20px;
}

.toggle{
    display: flex;
    gap: 1rem;
    align-items: center;
}

span.switcher label {
    z-index: 1;
    position: absolute;
    top: 10px;
    bottom: 10px;
    border-radius: 20px;
}

span.switcher.switcher1 input {
    transition: 0.25s -0.1s;
}

span.switcher.switcher1 input:checked {
    background-color: #fff;
}

span.switcher.switcher-1 input:checked:before {
    color: #fff;
    transition: color 0.5s 0.2s;
}

span.switcher.switcher1 input:checked:after {
    color: #ccc;
    transition: color 0.5s;
}

span.switcher.switcher1 input:checked + label {
    left: 10px;
    right: 100px;
    background: #1e1e1e;
    transition: left 0.5s, right 0.4s 0.2s;
}

span.switcher.switcher1 input:not(:checked) {
    background: #1e1e1e;
    transition: background 0.5s -0.1s;
}

span.switcher.switcher1 input:not(:checked):before {
    color: #ccc;
    transition: color 0.5s;
}

span.switcher.switcher1 input:not(:checked):after {
    color: #1e1e1e;
    transition: color 0.5s 0.2s;
}

span.switcher.switcher1 input:not(:checked) + label {
    left: 100px;
    right: 10px;
    background: #fff;
    transition: left 0.4s 0.2s, right 0.5s, background 0.35s -0.1s;
}

@media (max-width:1115px){
    .container{
        padding: 0 0 0 3.5rem;
    }
}

@media (max-width:550px){
    .container{
        padding: 0 0 4rem 0;
    }
    .filter{
        flex-direction: column;
        gap: 2rem;
    }
    .filter > div:nth-child(1){
        align-self: flex-start;
    }
    .filter > div:nth-child(2){
        align-self: flex-end;
    }
    .select{
        width: 100%;
    }
    .box{
        width: 100%;
    }
    .toggle{
        width: 100%;
     justify-content: space-between;
    }
    .range{
        justify-content: center !important;
    }
}


@media (max-width:440px){
    .header h2{
        font-size: 1.3rem;
    }

    .box h2:nth-child(1){
        font-size: 1.2rem;
    }
}