.container {
    width: 100%;
    padding-top: 94px;
    min-height: 100vh;
}

.wrapper {
    padding-top: 4rem;
    position: relative;
    z-index: 1;
}

.heroSection {
    background: url('/public//img/hero_bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4rem 0 4rem 5rem;
    margin-bottom: 4rem;
    width: 100%;
}

.heroImg {
    width: 100%;
    background: url('/public//img/hero_img.png');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 75%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
}

.heroImg h1 {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 5em;
    font-style: normal;
    font-weight: 600;
    line-height: 100px;
    text-transform: uppercase;
}

.desc p {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
}

.fastBicycle {
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: 500px;
    margin-bottom: 4rem;

}

.leftSec {
    position: absolute;
    left: 0;
    transform: translateY(-50%);
    width: 50%;
    height: 75%;
    top: 50%
}

.leftSec img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.rightSec {
    width: 60%;
    padding: 4rem 0;
    background: #b0d6fa31;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    align-items: flex-end;
}

.inner {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.rightSec h1 {
    font-family: 'Inter', sans-serif;
    padding: 2rem 0 1.5rem 0;
    font-family: 'Inter', sans-serif;
    font-size: 3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
    position: relative;

}

.rightSec h1::before {
    content: '';
    position: absolute;
    background-color: #ED4C21;
    left: 0;
    bottom: 0;
    width: 34.64px;
    height: 3px;
    border-radius: 4px;
}

.rightSec p {
    color: #122333;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 23.438px;
}

.localBusiness {
    padding: 4rem 0;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #122333;
    gap: 1rem;
    margin-bottom: 4rem;
}


.circle span.outer {
    display: inline-block;
    padding: 1rem;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.10);

}

.circle {
    text-align: center;
}

.circle span.inner {
    padding: 2.5rem;
    border-radius: 50%;
    background-color: #ED4C21;
}

.descBusiness {
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 47.531px;
    text-transform: capitalize;
}

.title {
    color: #ED4C21;
    text-align: center;
    font-family: 'Khand', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 19.125px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.ourServices {
    margin-bottom: 2rem;
    /* display: flex;
    justify-content: center; */
}

.ourServices h2,
.fastTeam h2 {
    padding: 4rem;
    color: #122333;
    text-align: center;
    font-family: 'Khand', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 24.75px;
    position: relative !important;
    letter-spacing: 1.1px;
    text-transform: uppercase;
}

.ourServices h2::before,
.fastTeam h2::before {
    content: 'What we do';
    color: #ece2e4;
    width: 100%;
    position: absolute !important;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 5.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 93.75px;
    letter-spacing: 1px;
    text-transform: uppercase;
    left: 50%;
    top: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
}

.fastTeam h2::before {
    content: 'Be Rider';
}

.services {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 4rem;
}

.cardContainer {
    padding: 3rem;
    background: #F2F8FA;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 2rem
}

.count span {
    color: #122333;
    font-family: 'Khand', sans-serif;
    font-size: 1.288;
    font-style: normal;
    font-weight: 600;
    line-height: 28.875px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.heading h1 {
    color: #122333;
    font-family: 'Inter', sans-serif;
    font-size: 1.938rem;
    font-style: normal;
    font-weight: 400;
    line-height: 47.937px;
}

.cardDesc p {
    color: #3D4F5F;
    font-family: 'Inter', sans-serif;
    font-size: 0.938rem;
    font-style: normal;
    font-weight: 400;
    line-height: 23.438px;
}

.bullets ul {
    padding-left: 17px;
}

.bullets ul li {
    color: #3D4F5F;
    font-family: 'Inter', sans-serif;
    font-size: 0.938rem;
    font-style: normal;
    font-weight: 400;
    line-height: 27.225px;
}

.bullets ul li:before {
    content: "\2022";
    color: red;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}


.happyClient {
    display: flex;
    width: 100%;
    margin-bottom: 4rem;
}

.happyClient .testimonials {
    background-color: #122333;
    flex: 1 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.happyClient .clients {
    flex: 1 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
}

.testimonials p:nth-child(1) {
    color: #ED4C21;
    font-family: 'Khand', sans-serif;
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 600;
    line-height: 19.17px;
    letter-spacing: 1.3px;
    text-transform: uppercase;
}

.testimonials p:nth-child(2) {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 25px;
    padding-right: 4rem;
}

.testimonials p:nth-child(3) {
    color: #ED4C21;
    font-family: 'Inter', sans-serif;
    font-size: 0.87rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18.2px;
}

.clients p {
    color: #ED4C21;
    font-family: 'Khand', sans-serif;
    font-size: 1.37;
    font-style: normal;
    font-weight: 600;
    line-height: 23.428px;
    letter-spacing: 0.9px;
    text-transform: uppercase;
}

.clients img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

@media (max-width:950px) {
    .heroImg h1 {
        font-size: 3rem;
        line-height: 60px;
    }

    .rightSec h1 {
        font-size: 2rem;
        line-height: 50px;
    }

    .rightSec p {
        font-size: 0.9rem;
    }

    .ourServices h2::before,
    .fastTeam h2::before {
        font-size: 4rem;
    }
}

@media (max-width:780px) {
    .heroSection {
        background: #122333;
    }

    .heroImg {
        background: none;
    }

    .leftSec {
        display: none;
    }

    .rightSec {
        width: 100%;
    }

    .inner {
        width: 100%;
    }

    .happyClient {
        display: none;
    }
}

@media (max-width:580px) {
    .heroSection {
        padding: 2rem;
    }

    .heroSection h1 {
        font-size: 2rem;
        line-height: 40px;
    }

    .heroSection p {
        font-size: 1rem;
    }
}


.teamsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.card {
    width: 270px;
    height: 434.75px;
}

.img {
    height: 359.75px;
    object-fit: cover;

}

.para {

    color: #122333;
    font-family: Inter;
    font-size: 19px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
}

.subtitle {

    color: #ED4C21;
    font-family: Source Sans Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}

.moreAbout {
    text-align: center;
    margin: 2rem 6rem;
}

@media(max-width:950px){
    .teamsContainer {
       
        grid-template-columns: 1fr 1fr;
        place-items: center;
    }
}
@media(max-width:780px){
    .teamsContainer {
        grid-template-columns: 1fr;
        place-items: center;
    }
}