.wrapper{
    margin-top: 8rem;
    min-height: calc(100vh - 10rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.status h2{
    font-family: Inter;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #ED4C21;
}

.red span{
    background-color: red;
}

.forgotPassword{
    margin-bottom: 4rem;
    text-align: center;
}

.select select{
    width: 100%;
    min-width: 300px;
    border: 1px solid #D9D9D9;
    font-family: var(--instruments);
    border-radius: 10px;
    padding:0.69rem;
    font-size: 1rem;
    outline: none;
    background: #fff;
}

.data{
    border: 2px solid black;
    border-radius: 15px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 3rem;
    padding: 2rem 0;
}

.forgotPassword a{
    color: #ED4C21;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1px;
    text-decoration-line: underline;
}
.forgotPassword a:visited{
    color: #ED4C21;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 1px;
    text-decoration-line: underline;
}