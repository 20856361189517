.tabHeader{
    display: flex;
    justify-content: space-around;
}

.container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.head{
    position: relative;
}

.head h2{
    margin: 3rem 0;
    padding: 0.6rem 0;
    text-align: center;
    flex:1 1;
    border: 1px solid rgba(0, 0, 0, 0.148);
    background:  #fff;
    font-family: Inter;
    font-size: 1.7rem;
    cursor: pointer;
    border-top: 0;
    font-style: normal;
    color: #ED4C21;
    font-weight: 500;
    line-height: normal;
}

.backButton{
    position: absolute;
    top:50%;
    left: 2%;
    cursor: pointer;
    transform: translateY(-50%);
}

.active{
    color: #ED4C21;
}

.tabContainer{
    margin-top: 4rem;
}

.container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.wrapper{
    display: flex;
    gap: 2rem;
}

.leftStaple{
    padding: 2rem;
    border-radius: 31.229px;
    background: #FFF;
    box-shadow: 8px 8px 24px 0px rgba(2, 2, 70, 0.09);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex: 1;
}

.pay{
    gap: 0.5rem !important;
}

/* .senderCity{
    display: flex;
}

.senderCity */

.rightStaple{
    padding: 2rem;
    flex: 2;
    border-radius: 31.229px;
    background: #FFF;
    box-shadow: 8px 8px 24px 0px rgba(2, 2, 70, 0.09);
}

.steps{
    display: flex;
    align-items: center;
    gap: 0.7rem;
}

.circle{
    position: relative;
    cursor: pointer;
    width: 20px;
    height:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #122333; */
    background-color: #E4E7EC;
    border-radius:50% ;
}

.circle span{
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #fff;
}

.steps h3{
    color: #020246;
    font-family: Inter;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    
}

.steps p{
    color:#667085;
    font-family: Inter;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}

.active{
    background-color: #020246;
}

.continue{
    display: flex;
    gap:1.5rem;
    justify-content: flex-end;
}

.personal{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.default{
    display: flex;
    gap: 0.5rem;
}

.fields{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.fields  div{
    display: flex;
    flex: 1 1;
    gap: 1.5rem;
}

.circle::after{
    content: '';
    position: absolute;
    background-color: #E4E7EC;
    height: 49px;
    bottom: -49px;
    width: 2px;
    transform-origin: bottom;
}

.steps:nth-child(2) .circle::after{
    height: 0;
    bottom: 0;
}

.select select{
    width: 100%;
    border: 1px solid #D9D9D9;
    font-family: var(--instruments);
    border-radius: 10px;
    padding:0.69rem;
    font-size: 1rem;
    outline: none;
    background: #fff;
}

.checkWrapper{
    display: flex;
    flex-direction: column;
}

.checkBoxContainer{
    display: grid !important;
    grid-template-columns: repeat(3,1fr);
}

.checkBox{
    display: flex;
    align-items: center;
    gap:0.5rem !important
}

.checkBox label{
    width: 100%;
}

.address input{
    width: 100%;
    outline: none;
    font-family: var(--instruments);
    font-weight: 600;
    color: #333333;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    padding: 1.1rem 1rem 0.6rem 1rem;
}

.address{
    position: relative;
    width: 100%;
}

.address label{
    position: absolute;
    color: #333333;
    font-size: 1rem;
    pointer-events: none;
    font-weight: 400;
    font-family: var(--instruments);
    letter-spacing: 1.3px;
    left: 1rem;
    top:50%;
    transition: all 300ms ease-in-out;
    transform: translateY(-50%);
}

.address  input:focus ~ label,
.address  input:not(:placeholder-shown) ~ label
{
    transform: translateY(-20px);
    font-size: 0.7rem;
}

.address  input:focus
{
    border-color: #ED4C21;
    box-shadow: 0 0 8px #f18f397e
}

@media (max-width:800px){
    .wrapper{
        flex-direction: column;
    }

    .leftStaple{
        flex-direction: row;
    }

    .steps{
        flex-direction: column;
    }

    .circle{
        align-self: flex-start;
    }

    .circle::after{
        height: 2px;
        bottom: auto;
        width: 127px;
        right: -127px;
    }
}

@media (max-width:550px){
    .fields  div{
        flex-direction: column;
    }

    .container{
        padding-bottom: 2rem;
    }
}