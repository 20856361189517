.loginContainer{
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(7px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    cursor: pointer;
}

.loginWrapper{
    position: fixed;
    background-color: #122333;
    top:50%;
    left:50%;
    border-radius: 40px;
    padding: 4rem;
    transform: translate(-50%,-50%);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.header{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
}

.header h1{
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -2px;
}

.header p{
    color: #898889;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1px;
}

.inputFields{
    display: flex;
    gap: 1.5rem;
}

.inputs{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1 1;
}

.oath{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1 1;
}
.inputs input{
    border-radius: 7px;
    padding: 14px;
    width: 100%;
    outline: none;
}

::placeholder{
    color: #898889;
    font-family: 'Inter', sans-serif;
}

.forgotPassword{
    margin-top: 2rem;
    text-align: center;
}

.forgotPassword a{
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    text-decoration-line: underline;
}
.forgotPassword a:visited{
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
    text-decoration-line: underline;
}

.btn button{
    border: none;
    text-align: center;
    width: 100%;
    padding: 13px;
    cursor: pointer;
    border-radius: 8px;
    background: linear-gradient(90deg, #EBD75D 0%, #ED4C21 61.77%);
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.oath button{
    border-radius: 8px;
    text-align: left;
    background:transparent;
    border: 1px solid #fff;
    width: 100%;
    padding: 11px;
    cursor:pointer ;
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.5px;
}

@media (max-width:950px){
    .header h1{
        font-size:1.5rem
    }
    .header p{
        font-size: 1rem;
    }

    .loginWrapper{
        width: 70%;
    }
}

@media (max-width:600px){
    .loginWrapper{
        width: 80%;
        padding: 3rem 1.5rem;
    }
}