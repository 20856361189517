.container{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper{
    background-color: #ececec;
    width: 750px;
    height: 80vh;
    display: flex;
}

.forgotPass{
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.forgotPass button{
    background: none;
    outline: none;
    border: none;
    text-decoration: underline;
    font-weight: bold;
    font-size: 1.1rem;
    cursor: pointer;
}

.left{
    border: 1px solid black;
    background: rgba(18, 35, 51, 0.90);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1rem;
}

.left .logo{
    width: 150px;
}

.left .logo img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.message h1{
    padding: 1rem 3rem;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 2.3rem;
    font-weight: 400;
    color: #FFF;
    line-height: 45px;
    text-align: center;
    /* background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.44) 100%); */
    background: #FFF;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.right{
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    gap: 1rem;
}

.fields{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.btn button{
    border: none;
    text-align: center;
    width: 100%;
    padding: 13px;
    cursor: pointer;
    border-radius: 8px;
    background: linear-gradient(90deg, #EBD75D 0%, #ED4C21 61.77%);
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.loginHead h2{ 
    color: var(--text-primary, #101012);
    font-family: 'Inter', sans-serif;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 28px */
}

@media (max-width:750px){
    .wrapper{
        flex-direction: column;
        background-color: #fff;
    }

    .left{
        background: none;
        border:none
    }

    .message{
        display: none;
    }
}